<template>
  <v-row
    v-if="freeErrors !== null"
    justify="center"
    class="fill-height text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('assignTask.title') }}</h1>
    </v-col>
    <v-row>
      <v-col
        cols="12"
        style="padding: 1rem;"
      >
        <v-select
          v-model="assignedUser"
          :item-text="'text'"
          :item-value="'id'"
          :items="freeUsers"
          :label="$t('assignTask.labelUser')"
        />
      </v-col>
      <v-col
        cols="12"
        style="padding: 1rem;"
      >
        <v-select
          v-model="assignedError"
          :item-text="'carType.subType'"
          :item-value="'id'"
          :items="freeErrors"
          :label="$t('assignTask.labelTask')"
        />
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-btn
        x-large
        color="primary"
        @click="assignToTask"
      >
        {{ $t('assignTask.assignTask') }}
      </v-btn>
    </v-col>
    <v-row
      align="center"
      justify="center"
    >
      <v-col sm="3">
        <v-alert
          v-if="error"
          max-width="400"
          type="error"
          dismissible
        >
          {{ $t('assignTask.wrong') }}
        </v-alert>
        <v-alert
          v-if="success"
          max-width="400"
          type="success"
          dismissible
        >
          {{ $t('assignTask.great') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>

export default {
  name: 'ErrorAssignDashboard',
  data () {
    return {
      freeUsers: null,
      freeErrors: null,
      success: false,
      error: false,
      assignedUser: null,
      assignedError: null
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const response = await this.$axios.get('/users/user-statistics')
        this.freeUsers = response.data.map(u => ({
          id: u.id,
          text: `${u.firstName} ${u.lastName} <${u.email}>`
        }))
        const response2 = await this.$axios.get('/car-errors/')
        const claimableTasks = response2.data.filter(task => task.canBeClaimed)
        const mutatedTasks = claimableTasks.map(carError => {
          if (carError.carType.subType) {
            return carError
          }
          return {
            ...carError,
            carType: {
              subType: carError.carType.name
            }
          }
        })
        this.freeErrors = mutatedTasks
      } catch (err) {
        console.error(err)
      }
    },
    async assignToTask () {
      const sendData = {
        userId: this.assignedUser,
        carErrorId: this.assignedError
      }
      await this.$axios.post('/car-errors/attach-user-to-error', sendData)
      const response = await this.$axios.get('/users/user-statistics')
      this.freeUsers = response.data
      const response2 = await this.$axios.get('/car-errors/')
      const claimableTasks = response2.data.filter(task => task.canBeClaimed)
      this.freeErrors = claimableTasks
    }
  }
}
</script>
